<template>
    <v-container fluid style="margin-bottom:100px">
        <v-row>
            <v-col cols="12">
                 <v-breadcrumbs :items="breadcumbs"></v-breadcrumbs>
                 <template v-slot:item="{ item }">
                 <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled" exact>
                 {{ item.text }}
                 </v-breadcrumbs-item>
                 </template>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Office</h6>
                <v-autocomplete
                    solo
                    v-model="office"
                    :items="offices"
                    item-value="office_id"
                    item-text="office_name"
                    label="Office"
                    @change="(event) => updateEmployee(event)"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Department</h6>
                <v-autocomplete
                    solo
                    v-model="department"
                    :items="departments"
                    item-value="dept_id"
                    item-text="dept_name"
                    label="Department"
                    @change="(event) => updateDivisi(event)"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Division</h6>
                <v-autocomplete
                    solo
                    v-model="division"
                    :items="divisions"
                    item-value="division_id"
                    item-text="division_name"
                    label="Division"
                    @change="(event) => updateEmployee(event)"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Employee</h6>
                <v-autocomplete
                    solo
                    v-model="employee"
                    :items="employees"
                    item-value="idemployee"
                    :item-text="item => `${item.idemployee} - ${item.name}`"
                    label="Employee"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Start Date</h6>
                <v-menu
                    ref="modal"
                    v-model="modal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        solo
                        v-model="date_from"
                        label="Start Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_from"
                        no-title
                        @input="modal = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">End Date</h6>

                <v-menu
                    ref="modal_to"
                    v-model="modal_to"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        solo
                        v-model="date_to"
                        label="End Date"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0 border-12"
                        hide-details=true
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_to"
                        no-title
                        @input="modal_to = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="mt-2" align="center" justify="center">
            <v-col cols="12" xs="12" sm="2" md="2">
                <v-btn block class="border-12  p-4" color="#f1f1f1" @click="clear()">Clear</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2">
                <v-btn block class="border-12 text-white p-4" color="primary" @click="search()">Search</v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-8">
            <v-col cols="12">
                <v-card class="rounded-l bg_card" style="border-top: 3px solid #1565C0">
                    <v-card-title>
                        Result
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field class="border-12" v-model="searchItem" append-icon="mdi-magnify" label="Search" solo single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>   
</template>

<script>
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'HRIS',
                disabled: false,
                href: '#',
                },
                {
                text: 'Attendance Report',
                disabled: true,
                href: '#',
                },
            ],
            offices: [],
            office: '',
            departments: [],
            department: '',
            divisions: [],
            division: '',
            employees: [],
            employee: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            itemLists: [],
            headers: [
                { text: 'ID', value: 'idemployee', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'NAME.', value: 'name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DEPT.', value: 'dept_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DIVISION', value: 'division_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'FULL TIME', value: 'FULL TIME', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SUNDAY.', value: 'SUNDAY', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ALPHA', value: 'ALPHA', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C1', value: 'C1', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C2', value: 'C2', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C3', value: 'C3', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C4', value: 'C4', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C5', value: 'C5', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUTI', value: 'CUTI', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUTI 1/2 HARI', value: 'CUTI 1/2 HARI', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'IJIN 1/2 HARI', value: 'IJIN 1/2 HARI', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'IJIN TIDAK MASUK', value: 'IJIN TIDAK MASUK', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'IJIN SAKIT', value: 'IJIN SAKIT', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'HOLIDAY', value: 'HOLIDAY', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SAKIT SURAT DOKTER', value: 'SAKIT SURAT DOKTER', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TUGAS LUAR', value: 'TUGAS LUAR', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'BELUM GABUNG', value: 'BELUM GABUNG', align: 'left', class: 'primary--text blue lighten-5' }
            ],
            loadingDatatable: false,
            searchItem: '',
        }
    },
    async mounted(){
        this.getOffice()
        this.getDepartment()
        this.getDivision()
        this.getEmployee()
        this.search()
    },
    methods:{
        async getOffice(){
            
            const respData = await backendApi.fetchCore('/api/master/sr/hris/getOffice', null, false, false, false)

            if (respData.status === 200) {
                this.offices = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }                
        },
        async getDepartment(){
            
            const respData = await backendApi.fetchCore('/api/master/sr/hris/getDepartment', null, false, false, false)

            if (respData.status === 200) {
                this.departments = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }                
        },
        async getDivision(){
            
            const respData = await backendApi.fetchCore(`/api/master/sr/hris/getDivision?dept_id=${this.department ? this.department : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.divisions = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }                
        },
        async getEmployee(){
            const respData = await backendApi.fetchCore(`/api/master/sr/hris/getEmployee?office_id=${this.office ? this.office : ''}&dept_id=${this.department ? this.department : ''}&division_id=${this.division ? this.division : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.employees = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }      
        },
        async updateEmployee(value){
            this.getEmployee()
        },
        async updateDivisi(value){
            this.getDivision()
        },
        async search(){
            this.loadingDatatable = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            const respData = await backendApi.fetchCore(`/api/sr/hris/attendance?office=${this.office ? this.office : ''}&department=${this.department ? this.department : ''}&division=${this.division ? this.division : ''}&employee=${this.employee ? this.employee : ''}&dateFrom=${start_date ? start_date : ""}&dateTo=${end_date ? end_date : ""}`, null, false, false, false)

            if (respData.status === 200) {
                this.loadingDatatable = false
                this.itemLists = respData.data.result
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }    
        },
        clear(){
            this.office = ''
            this.department = ''
            this.division = ''
            this.employee = ''
            this.date_from = ''
            this.date_to = ''
        }
    }
    
}
</script>